import IMask from 'imask';
require('./bootstrap');

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})

/*
// Require Vue
window.Vue = require('vue').default;

// Register Vue Components
Vue.component('example-component', require('./components/ExampleComponent.vue').default);

// Initialize Vue
const app = new Vue({
    el: '#app',
});
*/
